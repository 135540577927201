import { createRootProps } from "@qundus.tc/dom.helpers/css";

export const THEME_VARS = createRootProps({
  object: {
    p: {
      bg: null,
      text: null,
    },
  },
});
